.custom-navbar {
    background-color: #1d4f2b;
  }

.inp{
    padding-bottom: 5px;
    border-bottom: 2px solid #aaa;
}

.input{
    border: none;
    outline: none;
    background-color: #fff;
    width: 200px;
    margin-top: 30px;
    padding-right: 10px;
    font-size: 17px ;
    color: #474b48;
}

.submit{
    border:none;
    outline: none;
    width: 200px;
    margin-top: 20px;
    padding: 5px 0;
    font-size: 20px;
    border-radius: 10px;
    letter-spacing: 1px;
    cursor: pointer;
    color: #fff;
    background: linear-gradient(45deg, #0ef,#1d4f2b);
}

.banner{
    position: absolute;
    top:0;
    right: 0;
    width: 300px;
    height: 100%;
    background: linear-gradient(to right, #0ef, #1d4f2b);
    clip-path: polygon(0 50%,100% -20%,100% 100%, 70% 100%);
    padding-right: 25px;
    text-align: right;
    display: flex;
    flex-direction:  column;
    justify-content: center;
    align-items: flex-end;
}

.wel_text{
    font-size: 40px;
    margin-top: 60px;
    line-height: 50px;
    margin-left: 20px;
    color:#fff;
}

.version{
    margin-top:10px;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #fff;
}

.pie{
    margin-top: 30px;
    letter-spacing: 0.5px;
    font-size: 14px;
}

.link{
    color: #0ef;
    text-decoration:  none;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title{
    font-size: 35px;
}

.wrapper{
    position: relative;
    width: 600px;
    height: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 3px solid #1d4f2b;
    box-shadow: 0 0 40px 0 #00a6bc;
}

::-webkit-input-placeholder{
    color: #777
}

body{
    background: #fff;
    display: grid;
    justify-content: center;
}