
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;800&display=swap');

* {
    font-family: 'Rubik', sans-serif;
  }
.custom-selector{
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: #888;
}
.custom-selector select{
    width: 200px;
    min-height: 45px;
    background: #ffffffef;
    /* border-radius: 10px; */
    /* border: 5px solid #585858; */
    outline: none;
    padding: 0 10px;
    width: 100%;
    cursor: pointer;
    margin-top: 5px;
    appearance: none;
    font-size: 20px;
}
.custom-selector:hover select:hover{
    border-color: rgba(124, 197, 231, 0.877);
}
.custom-selector:focus select:focus{
    box-shadow: 0 0 8px rgba(104, 161, 199, 0.9) !important;
}
.custom-selector::after{
    content: '';
    border-width: 5px;
    border-style: solid;
    border-color: transparent;
    border-top-color: #222;
    display: inline-block;
    position: absolute;
    right: 15px;
    bottom: 19px;
    border-radius: 2px;
}

.loadDiv{
    pointer-events: none;
    opacity: 0.4;
    transition: 1s;
}


.btnadd{
    font-size: 30px;
}
.btnaddCalidad{
    font-size: 30px;
}
.textDashTable{
    font-size: 12px;
}
.checkPendiente{
    width: 30px;
    height: 30px;
}

/* select {
    border: 1.5px solid #585858 !important; */
    /* border-radius: 5px !important; */
    /* font-size:20px !important;
} */
.text-primera{
    font-size: 25px;
    color: rgb(40, 143, 26);
    font-weight: bold;
}
.valor-primera{
    font-size: 20px;
    color: rgb(40, 143, 26);
    font-weight: lighter;
}
.text-segunda{
    font-size: 25px;
    color: rgb(44, 99, 36);
    font-weight: bold;
}
.valor-segunda{
    font-size: 20px;
    color: rgb(44, 99, 36);
    font-weight: lighter;
}
.text-descarte{
    font-size: 25px;
    color: rgb(16, 43, 13);
    font-weight: bold;
}
.valor-descarte{
    font-size: 20px;
    color: rgb(16, 43, 13);
    font-weight: lighter;
}
.fa-file-pdf{
    font-size: 40px;
    cursor: pointer;
}
.fleteo{
    
    border: 1px solid #152a58b9 !important;
    border-radius: 5px !important;
    box-shadow: 0 0 8px #152a58b9 ;
}
.afactura{
    border: 1px solid #7a1818b9 !important;
    border-radius: 5px !important;
    box-shadow: 0 0 8px #7a1818b9 ;
}
h5 .montosFlete{
    border: 1px solid #152a58b9 ;
    border-radius: 5px ;
    color: #152a58b9;
    font-size: 30px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding: 5px;
    box-shadow: 0 0 3px #152a58b9 ;
}
h6 .montosFlete{
    border-bottom: 1px solid #152a58b9 ;
    /* border-radius: 5px ; */
    color: #152a58b9;
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding: 5px;
    /* box-shadow: 0 0 3px #152a58b9 ; */
}
.checkTable{
    font-size: 30px;
}
.push{
    cursor: pointer;
}
.push:hover{
    background-color:rgb(197, 197, 197);
}

.proyeccionCerrada{
    
    border-radius: 20px ;
    background-color: rgb(79, 128, 26)!important;
    color: rgb(247, 247, 247);
}
.proyeccionAbierta {
    border: 0.8px solid rgb(79, 128, 26)!important;
    border-radius: 20px ;
    background-color:rgb(229, 226, 226)!important;
    cursor: pointer;
}
.proyeccionAbierta:hover{
    box-shadow: 0 0 10px rgb(79, 128, 26) ;
}
.titleForm{
    font-size: 30px;
    font-weight: 600;
}
.tablaResumenPro{
    font-size: 18px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* TABLE RESPONSIVE CSS ##################################### */

.imgdb {
    height: 70px;
    /* width: 60vw; */
    /* align-items: center; */
  }
  .imgtabla {
    height: 45px;
  }
.table-responsive-lg {max-height: 60vh; min-width: 80%;}
.tableFixHead          { overflow: auto;   }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }
.tableFixHeadPallet        { overflow: auto; height: 350px; }
.tableFixHeadPallet thead th { position: sticky; top: 0; z-index: 1; }
/* Just common table stuff. Really. */
.table-responsive{
    min-height: 200px;
    max-height: 80vh;
}
.table-responsive-sm{
    min-height: 200px;
    max-height: 400px;
    /* box-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696; */
}
/* .table-responsive-md{
    min-height: 200px;
    max-height: 60vh;

} */
table  { border-collapse: collapse; min-height: 200px; }
th, td { padding: 8px 16px;}
th     { background:#eee; }

/* CURSOR MOINTER */
.tabledb{
  cursor: pointer;
}

.tabledb:hover{
  background-color: rgb(102, 102, 102);
  /* font-size: 30px; */
  color: #eee;
}
.iconExcel{
    cursor: pointer;
}
.loadContainerGif{
    width: 100vw;
    height: 100vh;
   margin: auto;
   text-align: center;
    display: flex;
    align-items: center;
   justify-content: center;
    opacity: 1;
    right: 0;
    top: 0;
    position: fixed;
    z-index: -10000;
    
    background-color: rgba(160, 160, 160, 0);
    /* border: 10px solid black; */
    transition: 0.2s ease-in;
}
.addOpacitiGif {
    background-color: rgba(27, 27, 27, 0.555);    
    z-index: 10000;
}
.opacityOn{
    opacity: 1;
}
.opacityOff{
    opacity: 0;
}

/* TABLE STIKY */
.tableFixedColum{
    /* font-family: "Fraunces", serif; */
    /* font-size: 125%; */
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 0.2px solid #afafaf;
}
.tableFixedColum td,
.tableFixedColum th {
  border-bottom: 0.2px solid #afafaf;
  border-right: 0.2px solid #afafaf;
  padding: 0.5rem 1rem;

}
.tableFixedColum thead th {
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  
  background: white;
  
}
.tableFixedColum td {
  background: #fff;
  padding: 4px 5px;
  text-align: center;
  box-shadow: rgba(26, 26, 26, 0.336) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.tableFixedColum thead th:first-child {
  position: sticky;
  top:0px;
  left: 0;
  z-index: 10;
  background-color: #e4e4e4;
  text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
  min-width: 150px;
  max-width: 150px;
}

.tableFixedColum thead th:nth-child(2) {
    position: sticky;
  
    left: 150px;
    z-index: 10;
    background-color: #e4e4e4;
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
    min-width: 150px;
  max-width: 150px;
  white-space: nowrap;
  }

.tableFixedColum tbody td:first-child {
  position: sticky;
  left: 0;
  background-color: #e4e4e4;
  z-index: 5;
}
.tableFixedColum tbody td:nth-child(2) {
    position: sticky;  
    left: 150px;
    z-index: 5;
    background-color: #e4e4e4;
    font-size: 16px;
   
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
    width: 100px;
    
}

.tableFixedColum tbody td:nth-child(2):hover{
    background-color: rgb(196, 196, 196);
    cursor: pointer;
}

/* TABLE STIKY COLUN 01 */
.tableFixedColumC1{
    /* font-family: "Fraunces", serif; */
    /* font-size: 125%; */
    white-space: nowrap;
    margin: 0;
    border-radius: 8px;
    /* border: 2px gray solid; */
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    /* border: 0.2px solid #afafaf; */
}
.tableFixedColumC1 td,
.tableFixedColumC1 th {
  border-bottom: 0.2px solid #afafaf;
  border-right: 0.2px solid #afafaf;
  padding: 0.5rem 1rem;

}
.tableFixedColumC1 thead th {
  /* padding: 5px; */
  position: sticky;
  top: 0;
  z-index: 1;
  text-align: center;
  background: white;
  
}
.tableFixedColumC1 td {
  background: #fff;
  padding: 1px 5px;
  text-align: center;
  /* font-weight: bold; */
  box-shadow: rgba(26, 26, 26, 0.336) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  white-space: pre-wrap;
  min-height: 150px;
  max-width: 150px;
}

.tableFixedColumC1 thead th:first-child {
  position: sticky;
  top:0px;
  left: 0;
  z-index: 10;
  background-color: #e4e4e4;
  text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
  min-width: 100px;
  max-width: 120px;
}

/* .tableFixedColumC1 thead th:nth-child(2) {
    position: sticky;
  
    left: 150px;
    z-index: 10;
    background-color: #e4e4e4;
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
    min-width: 150px;
  max-width: 150px;
  white-space: nowrap;
  } */

.tableFixedColumC1 tbody td:first-child {
  position: sticky;
  left: 0;
  background-color: #e4e4e4;
  z-index: 5;
  height: 100px;
  padding: 1px 4px 1px;
  margin: 1px 4px 1px;
}
/* .tableFixedColumC1 tbody td:nth-child(2) {
    position: sticky;  
    left: 150px;
    z-index: 5;
    background-color: #e4e4e4;
    font-size: 16px;
   
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
    width: 100px;
    
}

.tableFixedColumC1 tbody td:nth-child(2):hover{
    background-color: rgb(196, 196, 196);
    cursor: pointer;
} */


/* TABLE 0 STYKE */
.tableFixedNone{
    /* font-family: "Fraunces", serif; */
    /* font-size: 125%; */
    white-space: nowrap;
    margin: 0;
    border-radius: 8px;
    /* border: 2px gray solid; */
    border-collapse: separate;
    border-spacing: 0;
    /* table-layout: fixed; */
    /* border: 0.2px solid #afafaf; */
    /* max-height: 300px; */
}
.tableFixedNone td,
.tableFixedNone th {
  border: 0.2px solid #afafaf;
  /* border-right: 0.2px solid #afafaf; */
  padding: 1rem;

}
.tableFixedNone thead th {
  /* padding: 5px; */
  position: sticky;
  top: 0;
  z-index: 1;
  text-align: center;
  background: rgb(53, 53, 53);
  color: white;
  
}
.tableFixedNone td {
  background: #fff;
  padding:0 10px 0;
  text-align: center;
  font-weight: 500;
  text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
  white-space: pre-wrap;
  /* height: 200px; */
  max-width: 300px;
}
.footer {
    position: fixed;
    left: 0px;
    bottom: 0;
    /* height: 30px; */
    width: 100%;
    background: #1d4f2b;
    text-align: end;
    color: white;
    padding-right: 20px;
    z-index: 10000;
}

