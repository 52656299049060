@media print {
    @page{
        /*size: 100mm 150mm;*/
        margin:0;
    }


    .rotated-view{ 
        transform: rotate(90deg)  scale(1.40);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-230mm;
        margin-left: 10mm;
    }

    

    .rotated-view-1{ 
        transform: rotate(90deg)  scale(1.6);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-220mm;
        margin-left: 50mm;
    }

    .rotated-view-2{ 
        transform: rotate(90deg)  scale(1.56);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-220mm;
        margin-left: 35mm;
    }
    .rotated-view-4{ 
        transform: rotate(90deg)  scale(1.60);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-237mm;
        margin-left: 40mm;
    }

    .rotated-view-6{ 
        transform: rotate(90deg)  scale(1.6);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-240mm;
        margin-left: 50mm;
    }

    .rotated-view-8{ 
        transform: rotate(90deg)  scale(1.57);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-250mm;
        margin-left: 50mm;
    }

    .rotated-view-10{ 
        transform: rotate(90deg)  scale(1.1);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-230mm;
        margin-left: 2mm;
    }
    /*Para 11 o mas items*/
    .rotated-view-11{ 
        transform:  scale(0.97);
        transform-origin: top right;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-20mm;
        margin-left: -7mm;
    }
    
   .rotated-view-A{ 
        transform: rotate(90deg)  scale(1.40);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-230mm;
        margin-left: 10mm;
    }
    /*Para 1 items*/
    .rotated-view-A-1{ 
        transform: rotate(90deg)  scale(1.3);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-200mm;
        margin-left: 10mm;
    }
    /*Para 2 items*/
    .rotated-view-A-2{ 
        transform: rotate(90deg)  scale(1.37);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-225mm;
        margin-left: 10mm;
    }
    /*Para 3 o 4 items*/
    .rotated-view-A-4{ 
        transform: rotate(90deg)  scale(1.30);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-230mm;
        margin-left: 10mm;
    }
    /*Para 5 o 6 items*/
    .rotated-view-A-6{ 
        transform: rotate(90deg)  scale(1.35);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-240mm;
        margin-left: 20mm;
    }
    /*Para 7 u 8 items*/
    .rotated-view-A-8{ 
        transform: rotate(90deg)  scale(1.2);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-200mm;
        margin-left: 10mm;
    }

    .rotated-view-A-10{ 
        transform: rotate(90deg)  scale(1.2);
        transform-origin: bottom left;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-250mm;
        margin-left: 20mm;
    }

    .rotated-view-A-11{ 
        transform:  scale(0.97);
        transform-origin: top right;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:-20mm;
        margin-left: -7mm;
    }
    
    
.p{
    border:1px solid #fff;
}
 
p{
    border:1px solid #fff;
}
    .compact-view {
        page-break-before: avoid;
        page-break-after: avoid;
        /*width: 150mm;*/ /* Ancho de la etiqueta */
        /*height: 100mm; *//* Altura de la etiqueta */
        border:1px solid #000;
    }
  }